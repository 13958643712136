import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { IntroComponent } from './intro/intro.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular-highcharts';
import { DataTablesModule } from 'angular-datatables';
import { HighchartsChartModule } from 'highcharts-angular';
import { InterceptorService } from './services/loader/interceptor.service';

import { CustomDirectiveDirective } from './dashboard/climate-projection/custom-directive.directive';
import { MapPopupComponent } from './map-popup/map-popup.component';

import { AgGridModule } from 'ag-grid-angular';
import { MaterialModule } from './dashboard/material.module';
import { SpecialBulletinFloodComponent } from './dashboard/special-bulletin-flood-old/special-bulletin-flood.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgHttpLoaderComponent } from 'ng-http-loader';
// import { ScrollButtonComponent } from './ScrollButton/scroll-button/scroll-button.component';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


// Create a function to load translation files
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json'); // Adjust path as necessary
}



@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    IntroComponent,
    CustomDirectiveDirective,
    MapPopupComponent,
    SpecialBulletinFloodComponent,
    // ScrollButtonComponent,
 



  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ChartModule,
    DataTablesModule,
    HighchartsChartModule,
    AgGridModule,
    NgbDatepickerModule,
    NgHttpLoaderComponent,
    MatIconModule, 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
